import ApiService from "@/store/services/api.services";

// action types
export const GET_QUOTATION = "getQuotation";

// mutation types
export const SET_QUOTATION = "setQuotation";

export const SET_ERROR = "setError";

const state = {
    errors: [],
	quotations: [],
};

const getters = {
	quotations(state) {
		return state.quotations;
	},
};

const actions = {
	[GET_QUOTATION](context,query) {
		return new Promise((resolve,reject) => {
			ApiService.get("/quotations",{params:query})
				.then(({ data }) => {
					context.commit(SET_QUOTATION, data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
                    reject(response);
				});
		});
	},
};

const mutations = {
	[SET_QUOTATION](state, data) {
		state.quotations = data;
	},
    [SET_ERROR](state, error) {
		state.errors = error;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
