import ApiService from "@/store/services/api.services";
import {map,findIndex,cloneDeep} from "lodash"
// action types
export const GET_PRODUCT = "getProduct";
export const GET_IU_PRODUCT = "getIUProduct";
export const GET_IU_LISTING = "getIuListing";

// mutation types
export const SET_PRODUCT = "setProduct";
export const SET_SELECTED_PRODUCTS_ID = "setSelectedProductsId";
export const SET_SELECTED_PRODUCTS = "setSelectedProducts";
export const SET_SELECTED_INSPECTIONS = "setSelectedInspections";
export const SET_SELECTED_INSPECTIONS_ID = "setSelectedInspectionsId";
export const SET_QUANTITY = "setQuantity";
export const SET_PRICE = "setPrice";
export const SET_ORDER_DETAILS = "setOrderDetails";
export const REMOVE_INSPECTION = "removeInspection";
export const SET_INSPECTION = "setInspection";
export const ORDER_COMPLETED = "orderCompleted";
export const SET_GST = "setGst";
export const SET_GST_DATA = "setGstData";
export const SET_DISCOUNT_DATA = "setDiscountData";
export const SET_DISCOUNT_STATUS = "setDiscountStatus";
export const SET_RELATED_PRODUCT = "setRelatedProduct";
export const SET_EWALLET_AMOUNT = "setEwalletAmount";
export const SET_DISCOUNT_LIST = "setDiscountList";
export const SET_OVERDUE_AMOUNT = "setOverdueAmount";
export const SET_CASHBACK = "setCashback";


export const SET_ERROR = "setError";

const state = {
	errors: [],
	//products: [],
	order_details:{},
	selected_products_id: [],
	selected_products: [],
	selected_inspections_id: [],
	selected_inspections: [],
	rate_of_gst:0,
	gst_data:{},
	discount_data:[],
	discount_status:false,
	related_product:[],
	ewallet_amount:0,
	discount_list:[],
	overdue_amount:0,
	cashback_details:{}
};

const getters = {
	// products(state) {
	// 	return state.products;
	// },
	selected_products(state) {
		return state.selected_products;
	},
	selected_inspections(state) {
		return state.selected_inspections;
	},
	selected_inspections_id(state){
		return state.selected_inspections_id
	},
	order_details(state){
		return state.order_details;
	},
	rate_of_gst(state){
		return state.rate_of_gst;
	},
	gst_data(state){
		return state.gst_data;
	},
	discount_data(state){
		return state.discount_data;
	},
	discount_status(state){
		return state.discount_status;
	},
	related_product(state){
		return state.related_product;
	},
	ewallet_amount(state){
		return state.ewallet_amount;
	},
	discount_list(state){
		return state.discount_list;
	},
	overdue_amount(state){
		return state.overdue_amount;
	},
	cashback_details(state){
		return state.cashback_details;
	}
};

const actions = {
	[GET_PRODUCT](context, query) {
		return new Promise((resolve, reject) => {
			ApiService.get("/getproducts", { params: query })
				.then(({ data }) => {
					//context.commit(SET_PRODUCT, data.Data);
					// console.log({data})
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[GET_IU_PRODUCT](context, query) {
		return new Promise((resolve, reject) => {
			ApiService.get("/getproducts", { params: query })
				.then(({ data }) => {
					//context.commit(SET_PRODUCT, data.Data);
					// console.log({data})
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[GET_IU_LISTING](context, query) {
		return new Promise((resolve, reject) => {
			ApiService.get("/IUInvantory", { params: query })
				.then(({ data }) => {
					//context.commit(SET_PRODUCT, data.Data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
					reject(response);
				});
		});
	},
	[REMOVE_INSPECTION](context,data){
		let products = cloneDeep(context.state.selected_inspections);
		const Index = findIndex(products, {
			Id: data.id,
			Type: data.type,
		});
		products.splice(Index,1);
		context.commit(SET_INSPECTION,products)
	},
	
};

const mutations = {
	[SET_EWALLET_AMOUNT](state, data){
		state.ewallet_amount = data;
	},
	[SET_DISCOUNT_LIST](state, data){
		state.discount_list = data;
	},
	[SET_PRODUCT](state, data) {
		state.products = data;
	},

	[SET_SELECTED_PRODUCTS_ID](state, data) {
		state.selected_products_id = data;
	},
	[SET_SELECTED_PRODUCTS](state, data) {
		state.selected_products = data;
	},
	[SET_SELECTED_INSPECTIONS](state, data) {
		state.selected_inspections = data;
	},
	[SET_SELECTED_INSPECTIONS_ID](state, data) {
		state.selected_inspections_id = data;
	},
	[SET_QUANTITY](state, data) {
		const newState = map(state.selected_inspections,(product) => {
			let _gst = 0;
			const _total_price = product.Price * data.qty;
			_gst = product.GSTAmount * data.qty;
			return (product.Id == data.id && product.Type == data.type)
				? {...product,TotalPrice: _total_price, Quantity: data.qty,TotalGST:_gst}
				: product;
		})
		state.selected_inspections = newState;
	},
	[SET_PRICE](state, data) {
		const newState = map(state.selected_inspections,(product) => {
			let _gst = 0;
			const _total_price = product.Price * product.Quantity;
			if (data.BitGST) {
				_gst = ((_total_price-product.discountAmount)*product.RateOfGST)/100;
			} 
			return (product.Id == data.id && product.Type == data.type)
				? {...product,TotalPrice: _total_price, Price: data.Price,TotalGST:_gst, AuthorizedId: data.AuthorizedId}
				: product;
		})
		state.selected_inspections = newState;
	},
	[SET_INSPECTION](state, data) {
		state.selected_inspections = data;
	},
	[SET_ORDER_DETAILS](state,data){
		state.order_details = data;
	},
	[ORDER_COMPLETED](state){
		state.order_details = {};
		state.selected_inspections = [];
		state.selected_inspections_id = [];
	},
	[SET_GST](state,data){
		state.rate_of_gst = data;
	},
	[SET_GST_DATA](state,data){
		state.gst_data = data;
	},
	[SET_DISCOUNT_DATA](state,data){
		state.discount_data = data;
	},
	[SET_DISCOUNT_STATUS](state,data){
		state.discount_status = data;
	},
	[SET_RELATED_PRODUCT](state,data){
		state.related_product = data
	},
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	[SET_OVERDUE_AMOUNT](state, data){
		state.overdue_amount = data;
	},
	[SET_CASHBACK](state, data){
		state.cashback_details = data;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
