<template>
  <v-app id="app">
    <router-view></router-view>
    <InternetConnectionLost :dialogstatus="lostInternetDialog" />
  </v-app>
</template>
<script>
import InternetConnectionLost from "@/views/pages/components/InternetConnectionLost";
export default {
  name: "App",
  data() {
    return {
      loading: true,
      onLineConnection: navigator.onLine,
      lostInternetDialog: navigator.onLine ? false : true,
    }
  },
  components: {
    InternetConnectionLost,
  },
  watch: {
    onLineConnection(v) {
      if (v) {
        this.lostInternetDialog = false;
      }
      else {
        this.lostInternetDialog = true;
      }
    }
  },
  methods: {
    getLoadingStatus() {

    },
    updateOnlineStatus(e) {
      const { type } = e
      console.log({ e });
      this.onLineConnection = type === 'online'
    }
  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  },
}
</script>
<style lang="scss"></style>
