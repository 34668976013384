import ApiService from "@/store/services/api.services";
import AuthService from "@/store/services/auth.services";

// action types
export const LOGIN = "login";
export const LOGOUT = "logout";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_LOGIN_ERROR = "setLoginError";

const state = {
	errors: [],
	user: AuthService.getCurrentUser(),
	isAuthenticated: !!AuthService.getToken(),
};

const getters = {
	getErrors(state){
		return state.errors;
	},
	currentUser(state) {
		return state.user;
	},
	isAuthenticated() {
		return state.isAuthenticated;
	},
};

const actions = {
	[LOGIN](context, credentials) {
		return new Promise((resolve,reject) => {
			ApiService.post("/Logins", credentials)
				.then(({ data }) => {
					context.commit(SET_AUTH, data);
					resolve(data);
				})
				.catch(({ response }) => {
					reject(response);
					context.commit(SET_LOGIN_ERROR, "response.data.Errors");
				});
		});
	},
	[LOGOUT](context) {
		context.commit(PURGE_AUTH);
	},
};

const mutations = {
	[SET_LOGIN_ERROR](state, error) {
		state.errors = error;
	},
	[SET_AUTH](state, data) {
		state.isAuthenticated = true;
		state.user = data.user;
		state.errors = {};
		AuthService.saveToken(data);
	},
	[PURGE_AUTH](state) {
		state.isAuthenticated = false;
		state.user = {};
		state.errors = {};
		AuthService.destroyToken();
		localStorage.removeItem('_location');
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
