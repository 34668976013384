import Vue from "vue";
import Vuex from "vuex";

import auth from "./module/auth.module";
import common from "./module/common.module";
import products from "./module/product.module";
import quotations from "./module/quotation.module";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth,
		products,
		common,
		quotations,
	},
});


