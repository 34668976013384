import ApiService from '@/store/services/api.services';
import { toSafeInteger, isArray } from "lodash";

// action types
export const ERROR = "error";
export const MESSAGE = "message";
export const GET = "get";
export const POST = "post";
export const PUT = "put";
export const DELETE = "delete";
export const DELETE_ID = "deleteId";
export const DELETE_TRANS_GROUP = "deleteTransGroup";
export const DOWNLOAD = "download";
export const GET_HOLD_COUNT = "getHoldCount";

// mutation types
export const SET_ERROR = "setError";
export const SET_MESSAGE = "setMessage";
export const SET_POS_USER = "setPosUser";
export const SET_HOLD_COUNT = "setHoldCount";
export const SET_ORDER_PAGE = "setOrderPage";
export const DISABLE_ORDER_ITEM = "disableOrderItem";

const state = {
	errors: [],
	messages: [],
	pos_user:{},
	hold_count:0,
	is_order_page:false,
	disable_order_item:true,
};

const getters = {
	errors(state) {
		return state.errors;
	},
	messages(state) {
		return state.messages;
	},
	current_user(state){
		return state.pos_user;
	},
	hold_count(state){
		return state.hold_count;
	},
	is_order_page(state){
		return state.is_order_page
	},
	disable_order_item(state){
		return state.disable_order_item
	},
	device_width(state){
		return state.device_width;
	}
};

const actions = {
	[ERROR](context, param) {
		context.commit(SET_ERROR, param);
	},
	[MESSAGE](context, param) {
		context.commit(SET_MESSAGE, param);
	},
	[GET](context, {url,query}) {
		return new Promise((resolve,reject) => {
			ApiService.get(url,{params:query})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({ response }) => {
                    reject(response);
				});
		});
	},
	[GET_HOLD_COUNT](context) {
		const query = {Status:'Hold'};
		return new Promise((resolve,reject) => {
			ApiService.get('/posorder-get',{params:query})
				.then(({ data }) => {
					resolve(data);
					context.commit(SET_HOLD_COUNT,data.RecordsFiltered)
				})
				.catch(({ response }) => {
                    reject(response);
				});
		});
	},
	[POST](context, {url,payload}) {
		return new Promise((resolve,reject) => {
			ApiService.post(url,payload)
				.then(({ data }) => {
					//context.commit(SET_MESSAGE,[{ model: true, message: data.Message }])
					resolve(data);
				})
				.catch(({response}) => {
					//console.log(response)
                    reject(response);
				});
		});
	},
	[DELETE](context, {url,data}) {
		return new Promise((resolve,reject) => {
			ApiService.delete(url,{data:data})
				.then(({ data }) => {
					resolve(data);
					this.dispatch(GET_HOLD_COUNT);
				})
				.catch(({response}) => {
                    reject(response);
				});
		});
	},
	[DELETE_ID](context, {url}) {
		return new Promise((resolve,reject) => {
			ApiService.delete(url)
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({response}) => {
                    reject(response);
				});
		});
	},
	[DELETE_TRANS_GROUP](context, {url,data}) {
		return new Promise((resolve,reject) => {
			ApiService.delete(url,{data:data})
				.then(({ data }) => {
					resolve(data);
				})
				.catch(({response}) => {
                    reject(response);
				});
		});
	},
	[PUT](context, {url,payload}) {
		return new Promise((resolve,reject) => {
			ApiService.put(url,payload)
				.then(({ data }) => {
					//context.commit(SET_MESSAGE,[{ model: true, message: data.Message }])
					resolve(data);
				})
				.catch(({response}) => {
					//console.log(response)
                    reject(response);
				});
		});
	},
	[DOWNLOAD](context, {url,payload}) {
		return new Promise((resolve,reject) => {
			ApiService.post(url,payload)
				.then(({ data }) => {
					//context.commit(SET_MESSAGE,[{ model: true, message: data.Message }])
					resolve(data);
				})
				.catch(({response}) => {
					//console.log(response)
                    reject(response);
				});
		});
	},
};

const mutations = {
	[SET_ERROR](state, error) {
		if (error && isArray(error)) {
			state.errors = error.map((row) => {
				row.timeout = toSafeInteger(3000);
				return row;
			});
		} else {
			state.errors = [];
		}
	},
	[SET_MESSAGE](state, message) {
		if (message && isArray(message)) {
			state.messages = message.map((row) => {
				row.timeout = toSafeInteger(3000);
				return row;
			});
		} else {
			state.messages = [];
		}
	},
	[SET_POS_USER](state, data) {
		state.pos_user = data;
	},
	[SET_ORDER_PAGE](state, data) {
		state.is_order_page = data;
	},
	[SET_HOLD_COUNT](state, data) {
		state.hold_count = data;
	},
	[DISABLE_ORDER_ITEM](state, data) {
		state.disable_order_item = data;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
