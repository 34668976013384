import objectPath from "object-path";
export const validationError = ({ data }) => {
	const errors = [];
	objectPath.del(data, "debug");
	for (let row in data) {
		errors.push({
			model: true,
			message: data[row].join(", "),
		});
	}
	return errors;
};