import { AES, enc } from "crypto-js";
import { isEmpty, isObject } from "lodash";
import {deleteCookie, getCookie } from "@/plugins/bt-cookie";
import { saveData, deleteData, getData } from "@/store/services/local.services";

const ID_TOKEN_KEY = "_access_token";
const ID_TOKEN_TYPE = "token_type";
const ID_TOKEN_EXPIRY = "expires_at";
const ID_CURRENT_USER = "_btusr_tmp";
const ID_MENU_LIST = "_btcsr_tmp";
const ID_PERMISSION_LIST = "_btpsr_tmp";
const ID_CLIENT_DETAILS = "_btcld_tmp";
const ID_LOCATION = "_location";
const ENCRYPTION_KEY = process.env.VUE_APP_ENCRYPTION_KEY;

const destroyAuth = () => {
	localStorage.removeItem(ID_TOKEN_KEY);
	deleteCookie(ID_TOKEN_KEY);
	deleteCookie(ID_TOKEN_TYPE);
	deleteCookie(ID_TOKEN_EXPIRY);
	deleteData(ID_CURRENT_USER);
	deleteData(ID_MENU_LIST);
	deleteData(ID_PERMISSION_LIST);
	deleteData(ID_CLIENT_DETAILS);
};

export const saveLocalStorage = (key, data) => {
	try {
		if (isEmpty(data) === false && isObject(data)) {
			let encrypted = AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
			saveData(key, encrypted);
		}
	} catch (error) {
		console.warn({ error });
	}
};

export const getLocalStorage = (key) => {
	try {
		let encrypted = getData(key);
		if (!encrypted) {
			return null;
		}
		let decrypted = AES.decrypt(encrypted, ENCRYPTION_KEY);
		return JSON.parse(decrypted.toString(enc.Utf8));
	} catch (error) {
		console.warn({ error });
	}
};

export const isAuthenticated = () => {
	return !!localStorage.getItem(ID_TOKEN_KEY);
};
/* export const isAuthenticated = () => {
	return !!getCookie(ID_TOKEN_KEY);
}; */

/* export const getToken = () => {
	return getCookie(ID_TOKEN_KEY);
}; */
export const getToken = () => {
	return localStorage.getItem(ID_TOKEN_KEY);
};
export const getLocation = () => {
	return localStorage.getItem(ID_LOCATION);
};

export const getTokenType = () => {
	return 'Bearer';
	// return getCookie(ID_TOKEN_TYPE);
};

export const getTokenExpiry = () => {
	return getCookie(ID_TOKEN_EXPIRY);
};

export const getCurrentUser = () => {
	return getLocalStorage(ID_CURRENT_USER);
};

/* export const saveToken = ({ user, token_type, expires_at, access_token }) => {
	setCookie({ key: ID_TOKEN_TYPE, value: token_type, expiry: expires_at });
	setCookie({ key: ID_TOKEN_KEY, value: access_token, expiry: expires_at });
	setCookie({ key: ID_TOKEN_EXPIRY, value: expires_at, expiry: expires_at });
	saveLocalStorage(ID_CURRENT_USER, user);
}; */
export const saveToken = ({Data}) => {
    localStorage.setItem(ID_TOKEN_KEY,Data);
};
export const saveLocation = (Location) => {
    localStorage.setItem(ID_LOCATION,Location);
};

export const destroyToken = () => {
	destroyAuth();
};

export default {
	getToken,
	getTokenType,
	getTokenExpiry,
	getCurrentUser,
	saveToken,
	saveLocalStorage,
	getLocalStorage,
	isAuthenticated,
	destroyToken,
};
