import axios from "axios";
// import {map} from "lodash";
import AuthService from "@/store/services/auth.services";
import { ErrorEventBus } from "@/assets/js/components/event"
import { validationError } from "@/assets/js/components/comman.lib"
const ApiService = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-type": "application/json",
    "Authorization":`${AuthService.getTokenType()} ${AuthService.getToken()}`
  }
});

// Add a request interceptor
ApiService.interceptors.request.use(
  function (config) {
    if (config.url.endsWith("/")) {
      config.url = config.url.slice(0, -1);
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
ApiService.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const response = error.response;
    if (response.status == 401) {
      window.localStorage.clear();
      window.location.href = `/login`;
      return Promise.reject(error);
    }
    if (response.status == 422) {
      const errors = validationError(response);
      ErrorEventBus.$emit("set-error", errors);
    } 
    if(response.status == 400){
      ErrorEventBus.$emit("set-error", [
        { model: true, message: "Something missing. Please Check and try again later." },
      ]);
    }else {
      if (response.data && response.data.message) {
        ErrorEventBus.$emit("set-error", [{ model: true, message: response.data.message }]);
      } else {
        ErrorEventBus.$emit("set-error", [
          { model: true, message: "Something went wrong. Please try again later." },
        ]);
      }
    }
    return Promise.reject(error);
  }
);

export default ApiService;

