import { serialize, parse } from "cookie";
import { fromUnixTime } from "date-fns";

export const setCookie = (param) => {
	try {
		const expiry = 60 * 60 * 24;
		document.cookie = serialize(param.key, param.value, {
			expires: param.expiry ? fromUnixTime(param.expiry) : expiry,
			path: "/",
		});
		return true;
	} catch (error) {
		return error;
	}
};

export const setTempCookie = (param) => {
	try {
		document.cookie = serialize(param.key, param.value, {
			maxAge: param.time,
			path: "/",
		});
		return true;
	} catch (error) {
		return error;
	}
};

export const setDataCookie = (param, maxAge) => {
	try {
		document.cookie = serialize(param.key, JSON.stringify(param.value), {
			maxAge: maxAge,
			path: "/",
		});
		return true;
	} catch (error) {
		return error;
	}
};

export const getDataCookie = (key) => {
	try {
		let result = parse(document.cookie);
		if (result[key]) {
			return JSON.parse(result[key]);
		}
		return new Object();
	} catch (error) {
		return error;
	}
};

export const deleteCookie = (key) => {
	try {
		document.cookie = serialize(key, null, {
			maxAge: -(60 * 60 * 24),
			path: "/",
		});
		return true;
	} catch (error) {
		return error;
	}
};

export const setCookieWithOptions = (param, options) => {
	try {
		document.cookie = serialize(param.key, param.value, options);
		return true;
	} catch (error) {
		return error;
	}
};

export const getCookie = (key) => {
	try {
		let result = parse(document.cookie);
		if (result[key]) {
			return result[key];
		}
		return null;
	} catch (error) {
		return error;
	}
};
