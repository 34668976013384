import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import "@/assets/scss/typography.scss"
import "@/assets/scss/style.scss"
import "@/assets/scss/responsive.scss"

import MainSetting from "@/assets/js/main.mixin";
Vue.use(MainSetting);

Vue.config.productionTip = false

Vue.prototype.$assetURL = (param) => {
  return process.env.VUE_APP_BASE_URL + param;
};

Vue.prototype.$apiURL = () => {
  return process.env.VUE_APP_API_URL;
};

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
