import MomentJS from "moment-timezone";
class MainSetting {
	install(Vue) {
		Vue.mixin({
			data() {
				return {
					deviceWidth: window.innerWidth,
					deviceHeight: window.innerHeight,
				}
			},
			created() {
				window.addEventListener("resize", this.resizeEventHandler);
			},
			destroyed() {
				window.removeEventListener("resize", this.resizeEventHandler);
			},
			methods: {
				goBack() {
					this.$router.go(-1);
				},
				async logError(error) {
					try {
						console.warn(error);
					} catch (error) {
						console.warn(error);
					}
				},
				setContentHeight() {
					//console.log({'route': this.$route});
					let element = document.querySelector('.height-fix');
					if (element) {
						let bodyRect = document.body.getBoundingClientRect();
						if (bodyRect) {
							let elemRect = element.getBoundingClientRect();
							let offset = elemRect.top - bodyRect.top;
							element.style.cssText = `
								height:${window.innerHeight - (offset)}px;
								overflow:auto;
							`;
						}
					}
				},
				openCart(){
					if(this.deviceWidth < 920){
						const element = document.querySelector('#app');
						element.classList.add('cart-opened');
					}
				},
				closeCart(){
					const element = document.querySelector('#app');
					const closeElement = document.querySelector('.cart-overlay');
					if(closeElement){
						closeElement.onclick = () => {
							element.classList.remove('cart-opened');
						}
					}
				},
				resizeEventHandler() {
					this.deviceWidth = window.innerWidth;
					this.deviceHeight = window.innerHeight;
					const _this = this;
					setTimeout(() => {
						_this.setContentHeight();
					}, 100)
				},
				formatDateTime(dateTime) {
					return MomentJS(dateTime).format("DD/MM/YYYY hh:mm a");
				},
				formatDateTimeRaw(dateTime) {
					return MomentJS(dateTime).format("YYYY-MM-DD HH:mm");
				},
				formatDate(date) {
					return MomentJS(date).format("DD/MM/YYYY");
				},
				formatDateRaw(date) {
					return MomentJS(date).format("YYYY-MM-DD");
				},
				roundUpDown(number){
					const n = parseInt(parseFloat(number).toFixed(2) * 100);
					const rem = n%5;
					let diff = 0;
					if(rem <= 2){
						diff = rem;
						return ((n-diff)/100).toFixed(2);
					}
					if(rem >= 3){
						diff = 5-rem;
						return ((n+diff)/100).toFixed(2);
					}
					return diff;
				},
				formatAmount(amount)
				{
					let text = "$0.00";
					if(amount > 0)
					{
						text = `$${amount.toFixed(2)}`
					}
					return text;
				}
			},
			mounted() {
				const _this = this;
				setTimeout(() => {
					_this.setContentHeight();
				}, 100)
				_this.closeCart();
			},
			errorCaptured(err, vm, info) {
				this.logError({ err, vm, info });
			},
		})
	}
}
export default new MainSetting();